import { AdvancedAxiosConfig, DonationBody, PaymentIntentBody } from "@lib/types";

import { apiGateway } from "./gateway";

const DONATIONS_URL_PREFIX = "/donations";
const PAYMENT_URL_PREFIX = "/payments";

export const donationsAPI = {
  makeDonation: async (body: DonationBody, options?: AdvancedAxiosConfig) => {
    const { data: res } = await apiGateway.post<
      DonationBody,
      { data: { data: { client_secret: string } } }
    >(DONATIONS_URL_PREFIX, body, options);

    return res.data;
  },

  makePaymentIntent: async (body: PaymentIntentBody, options?: AdvancedAxiosConfig) => {
    const { data: res } = await apiGateway.post<
      PaymentIntentBody,
      { data: { data: { client_secret: string } } }
    >(`${PAYMENT_URL_PREFIX}/intent`, body, options);

    return res;
  },
};
