import { parseQueryFilters } from "@lib/helpers";
import {
  AdvancedAxiosConfig,
  ErrorUnprocessable,
  ListFilters,
  User,
  UserDocument,
  UsersList,
} from "@lib/types";

import { apiGateway } from "./gateway";

const USER_URL_PREFIX = "/users";
const DOCUMENTS_URL_PREFIX = "/documents";

export const userAPI = {
  getUsersList: async (options?: AdvancedAxiosConfig, filters?: Partial<ListFilters>) => {
    const url = parseQueryFilters(USER_URL_PREFIX, filters);
    const { data: res } = await apiGateway.get<Partial<ListFilters>, { data: UsersList }>(
      url,
      options,
    );

    return res;
  },

  getCurrentUser: async (userId?: string, options?: AdvancedAxiosConfig) => {
    const { data: res } = await apiGateway.get<{
      data: Partial<User>;
    }>(`${USER_URL_PREFIX}/${userId}`, options);

    const currentData = res?.data;

    Object.keys(currentData).forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (!currentData[key]) delete currentData[key];
    });

    if (currentData?.address) {
      Object.keys(currentData.address).forEach((key) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (!currentData.address[key]) delete currentData.address[key];
      });
    }

    return currentData;
  },

  updateUserInfo: async (
    { userId, ...data }: Partial<User> & { userId: string },
    options?: AdvancedAxiosConfig,
  ) => {
    return await apiGateway.patch<Partial<User>, void | ErrorUnprocessable>(
      `${USER_URL_PREFIX}/${userId}`,
      data,
      options,
    );
  },

  uploadUserDocument: async (data: UserDocument, options?: AdvancedAxiosConfig) => {
    return await apiGateway.post<UserDocument, void | ErrorUnprocessable>(
      `${DOCUMENTS_URL_PREFIX}/user`,
      data,
      options,
    );
  },
};
