"use client";

import { useRouter } from "next/navigation";

import { FC, ReactElement, createContext, useCallback, useContext, useState } from "react";
import { FieldValues } from "react-hook-form";
import { toast } from "react-toastify";

import { AxiosError } from "axios";

import { authAPI, proxyAPIGateway } from "@api";

import { handleErrorToast } from "@lib/helpers";
import { AppRoutes, ErrorFlat, ErrorUnprocessable, User } from "@lib/types";

interface Props {
  children: ReactElement | ReactElement[];
  authenticated: boolean;
  user?: Partial<User>;
}

const noopFunction = () => console.warn("Initialize User Context Provider first");
const asyncDefault = async () => {};

interface ReturnType {
  setUserData: (data: Partial<User>, persist?: boolean) => void;
  resetUserData: () => void;
  isAuthenticated: boolean;
  user?: Partial<User>;
  isLoading: boolean;
  login: (data: FieldValues) => Promise<User | void>;
  onSocialLogin: (code: string) => Promise<void>;
  register: (data: FieldValues) => Promise<void>;
  logout: () => Promise<void>;
}

export const SessionContext = createContext<ReturnType>({
  resetUserData: noopFunction,
  setUserData: noopFunction,
  isAuthenticated: false,
  isLoading: false,
  login: asyncDefault,
  onSocialLogin: asyncDefault,
  register: asyncDefault,
  logout: asyncDefault,
});

export const useSession = (): ReturnType => useContext(SessionContext);

export const SessionProvider: FC<Props> = ({ authenticated, children, user: persistedUser }) => {
  const router = useRouter();
  const [user, setUser] = useState<Partial<User> | undefined>(persistedUser);
  const [isAuthenticated, setIsAuthenticated] = useState(authenticated);
  const [isLoading, setLoading] = useState(false);

  const setUserData = useCallback((payload: Partial<User>) => {
    setUser((prev) => ({ ...prev, ...payload }));
    setIsAuthenticated(true);
  }, []);

  const resetUserData = useCallback(() => {
    setUser(undefined);
    setIsAuthenticated(false);
  }, []);

  const register = useCallback(async ({ email, display_name, password }: FieldValues) => {
    setLoading(true);
    try {
      await authAPI.registerNewUser({
        email,
        display_name,
        password,
        password_confirmation: password,
      });

      toast.success("User was successfully created. Please, check your email", {
        theme: "colored",
      });
    } catch (err) {
      const error = err as AxiosError;
      handleErrorToast(error.response?.data as ErrorUnprocessable);
    } finally {
      setLoading(false);
    }
  }, []);

  const onSocialLogin = useCallback(
    async (code: string) => {
      setLoading(true);
      try {
        const {
          data: { user: currentUser },
        } = await proxyAPIGateway.post("/auth/social", { code });

        setUserData(currentUser);
        router.push(AppRoutes.DASHBOARD);
      } catch (err) {
        const error = err as AxiosError;
        handleErrorToast(error.response?.data as ErrorUnprocessable);
      } finally {
        setLoading(false);
      }
    },
    [router, setUserData],
  );

  const login = useCallback(
    async (data: FieldValues) => {
      setLoading(true);
      try {
        const {
          data: { user: currentUser },
        } = await proxyAPIGateway.post("/auth/login", data);

        setUserData(currentUser);
        router.push(AppRoutes.DASHBOARD);
      } catch (err) {
        handleErrorToast((err as AxiosError).response?.data as ErrorFlat);
      } finally {
        setLoading(false);
      }
    },
    [router, setUserData],
  );

  const logout = useCallback(async () => {
    setLoading(true);
    try {
      await proxyAPIGateway.post("/auth/logout");
      resetUserData();
      router.push(AppRoutes.MAIN);
    } catch (error) {
      handleErrorToast((error as Error).message as string);
    } finally {
      setLoading(false);
    }
  }, [resetUserData, router]);

  return (
    <SessionContext.Provider
      value={{
        isLoading,
        user,
        setUserData,
        isAuthenticated,
        resetUserData,
        register,
        onSocialLogin,
        login,
        logout,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};
