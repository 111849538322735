"use client";

import { FC, ReactElement, createContext, useContext } from "react";

import { CommonData } from "@lib/types";

interface Props extends CommonData {
  children: ReactElement | ReactElement[];
}

const initialValues: CommonData = {
  countries: [],
  categories: [],
  currencies: [],
  trusted: [],
  causes: [],
  businesses: [],
  authenticated: false,
  stripeKey: "",
};

export const CommonDataContext = createContext<CommonData>(initialValues);

export const useCommonDataContext = (): CommonData => useContext(CommonDataContext);

export const CommonDataProvider: FC<Props> = ({ children, ...common }) => {
  return <CommonDataContext.Provider value={common}>{children}</CommonDataContext.Provider>;
};
