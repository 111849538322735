import { AdvancedAxiosConfig, SearchResponseBody } from "@lib/types";

import { apiGateway } from "./gateway";

const API_URL_PREFIX = "/search";

export const searchAPI = {
  globalSearch: async (options?: AdvancedAxiosConfig) => {
    const { data: res } = await apiGateway.get<{ results: SearchResponseBody[] }>(
      API_URL_PREFIX,
      options,
    );

    return res.results;
  },
};
