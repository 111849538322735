export const REQUIRED_MESSAGE = "This field is required";

export const PLATFORM_SOCIAL = Object.freeze({
  telegram: "https://t.me/joinchat/FuQGSRJkhCx3gEdlupYL9g",
  discord: "https://discord.gg/BKu2wQq",
  twitter: "https://twitter.com/causevest180",
  youtube: "https://youtube.com/causevest180",
});

export const SERVER_ERROR_MESSAGE = "Something went wrong on server";
